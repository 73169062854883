import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";
import { CareerBlock } from "../../components/General/General";

import careerHeader from "../../assets/img/career-header.jpeg";

function TechnicalSalesFT () {
    return(
        <>
            <MetaData 
                title="Technical Sales in reLi Energy"
                description="Join reLi Energy's team and contribute to reshaping the landscape of energy optimization and storage."
                image={careerHeader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <CareerBlock
                position="Technical Sales - Full Time"
                applicationid="S011"
                responsibilities={[
                    "Customer Engagement: Build and maintain strong relationships with prospective and existing clients, understanding their unique needs and effectively communicating the value of our solutions.",
                    "Technical Expertise: Demonstrate a deep understanding of our technology, providing technical insights to clients and assisting them in integrating our solutions into their energy systems.",
                    "Sales Strategy: Develop and implement effective sales strategies, identifying key opportunities, and collaborating with the broader team to achieve revenue targets.",
                    "Lead Generation: Proactively identify and pursue new business opportunities, including attending industry events, conferences, and networking to expand our client base.",

                ]}
                requirement={[
                    "Technical Background: Bachelor's degree in a relevant technical field, or business.",
                    "Sales Experience: Proven experience in technical sales, with a track record of achieving and exceeding sales targets.",
                    "Energy Sector Knowledge: Familiarity with the energy storage sector, battery technologies, and/or renewable energy systems.",
                    "Communication Skills: Excellent verbal and written communication skills in English or German, with the ability to convey complex technical information to diverse audiences.",
                    "Team Player: Collaborative mindset with the ability to work seamlessly within a cross-functional team.",
                ]}
                benifits={[
                    "Wide opportunity for networking and exposure to entrepreneurship",
                    "Collaborative and inclusive work environment",
                    "Opportunities for innovation ",
                    "Flexible work hours and the ability to work remotely ",
                    "Being part of a diverse, dynamic and enthusiastic team",
                    "Startup salary and equity options",
                    "Opportunity to work on cutting-edge projects in sustainable energy.",
                    "Collaborative and innovative work environment.",

                ]}
            />
            <FooterBar/>
        </>
    )
}

export default TechnicalSalesFT;