import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, ProductTiles } from '../../components/ProductSolution/ProductSolution';

import {    
    ProductBatteryAnalytics,
    ProductBatteryCommunication,
    ProductBatteryControl,
    ProductBatteryCostFunction,
    ProductBatteryOptimization,
    ProductBatterySimulation
} from './ProductsList';

import header from '../../assets/img/default-header.jpeg';

import "../page.css";

const CIBatteryInstallers = () =>{
    return(
        <>
        <MetaData 
                title="C&I Battery Installers"
                description="Assist in battery communication, remote monitoring and maintenance"
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar />
            <ImageHeader 
                image={header}
                head1="C&I BATTERY INSTALLERS"
                head2="Seamless integration, monitoring and maintenance of your battery instalations"
                content="Develop embedded software for integrating battery asset and build cloud platform to monitor and maintain the installations"
            />
            <ProductTiles 
                products={[
                    ProductBatteryAnalytics,
                    ProductBatteryControl,
                    ProductBatteryCommunication
                ]}
            />
            <FooterBar />
        </>
    )
}

export default CIBatteryInstallers;