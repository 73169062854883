import React from "react";

import NavBar from "../../../components/NavBar/NavBar";
import FooterBar from "../../../components/FooterBar/FooterBar";
import MetaData from "../../../components/MetaData/MetaData";
import { SmallImageHeader } from "../../../components/General/General";

import demoheader from '../../../assets/img/demo-header.jpeg';
import DemoImg from "../../../components/DemoImg/DemoImg";

function Demo() {
    return (
        <>
            <MetaData 
                title="Experience reLi in Action: Request a Personalized Demo for Battery Optimization"
                description="See the power of reLi's battery optimization firsthand. Request a demo to explore how our cutting-edge technology maximizes battery performance, ensuring efficiency and longevity."
                image={demoheader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <SmallImageHeader
                image={demoheader}
                head1='Try our solution'
                head2="Experience how reLi's solution maximizes battery profitability and lifetime"
            />
            <DemoImg/>
            <FooterBar/>
        </>
    );
}

export default Demo;