import React from "react";
import {Helmet} from "react-helmet";

const MetaData = ({title, description, image, url}) =>{
    return (
        <Helmet>
            <meta name=" description" content={description}/>
            <meta name="robots" content="all"/>
            <link rel="canonical" href={url} />
            <meta property="og:url" content={url}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content={process.env.REACT_APP_APP_HOST+image}/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content="relienergy.de"/>
            <meta property="twitter:url" content={url}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content={process.env.REACT_APP_APP_HOST+image}/>
            <title>{title}</title>
        </Helmet>
    )
}

export default MetaData;