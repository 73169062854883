import React from 'react';

import DemoForm from './DemoForm';

import demosimg from '../../assets/img/demos-img.jpeg'

import './DemoImg.css'

const DemoImg = () =>{
    return (
        <div className='demos'>
            <div className='demos-contacts'>
                <DemoForm/>
            </div>
            <div className='demos-img-div'>
                <img className='demos-img' src={demosimg}></img>
            </div>
            </div>
    )
}

export default DemoImg;