import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, AdministrativePage, HeadOne, HeadTwo, HeadThree, Content, List } from "../../components/Administrative/Administrative";

const PrivacyPolicy = () =>{
    return(
        <>
            <MetaData 
                title="Privacy Policy - reLi Energy"
                description=""
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <ImageHeader
             head1="Privacy Policy"
            />
            <AdministrativePage>
            <Content
            content="Last updated: November 10, 2023"
            />
            <Content
            content="This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. We attach great importance to the protection of your personal data and its security in accordance with the EU General Data Protection Regulation (GDPR)"
            />
            <Content
            content="We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy."
            />
            <HeadOne
            head="Interpretation and Definitions"
            />
            <HeadTwo
            head="Interpretation"
            />
            <Content
            content="The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural."
            />
            <HeadTwo
            head="Definitions"
            />
            <Content
            content="For the purposes of this Privacy Policy:"
            />
            <List
            list={[
            {head: "Account",content:" means a unique account created for You to access our Service or parts of our Service."},
            {head: "Affiliate",content:" means an entity that controls, is controlled by or is under common control with a party, where \"control\" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority."},
            {head: "Company",content:" (referred to as either \"the Company\", \"We\", \"Us\" or \"Our\" in this Agreement) refers to reLi Energy GmbH, Robert-Bosch-Straße 7, 64293 Darmstadt."},
            {head: "Cookies",content:" are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses."},
            {head: "Country",content:" refers to: Hessen, Germany"},
            {head: "Device",content:" means any device that can access the Service such as a computer, a cellphone or a digital tablet."},
            {head: "Personal Data",content:" is any information that relates to an identified or identifiable individual."},
            {head: "Service",content:"refers to the Website."},
            {head: "Service Provider",content:" means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used."},
            {head: "Usage Data",content:" refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit)."},
            {head: "Website",content:" refers to reLi Energy, accessible from https://relienergy.de/"},
            {head: "You",content:" means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable."}
            ]}
            />
            <HeadOne
            head="Collecting and Using Your Personal Data"
            />
            <HeadTwo
            head="Types of Data Collected"
            />
            <HeadThree
            head="Personal Data"
            />
            <Content
            content="While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:"
            />
            <List
            list={[
            {content:"Email address"},
            {content:"First name and last name"},
            {content:"Phone number"},
            {content:"Usage Data"}
            ]}
            />
            <HeadThree
            head="Usage Data"
            />
            <Content
            content="Usage Data is collected automatically when using the Service."
            />
            <Content
            content="Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data."
            />
            <Content
            content="When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data."
            />
            <Content
            content="We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device."
            />
            <HeadThree
            head="Tracking Technologies and Cookies"
            />
            <Content
            content="We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:"
            />
            <List
            list={[
            {head: "Cookies or Browser Cookies.",content:" A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies."},
            {head: "Web Beacons.",content:" Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity)."}
            ]}
            />
            <Content
            content={"Cookies can be \"Persistent\" or \"Session\" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies on the Free Privacy Policy website article."}
            />
            <Content
            content="We use both Session and Persistent Cookies for the purposes set out below:"
            />
            <List
            list={[
            {head: "Necessary / Essential Cookies",content:" - Type: Session Cookies; Administered by: Us; Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services."},
            {head: "Cookies Policy / Notice Acceptance Cookies",content:" - Type: Persistent Cookies; Administered by: Us; Purpose: These Cookies identify if users have accepted the use of cookies on the Website."},
            {head: "Functionality Cookies",content:" - Type: Persistent Cookies; Administered by: Us; Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website."}
            ]}
            />
            <Content
            content="For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy."
            />
            <HeadTwo
            head="Use of Your Personal Data"
            />
            <Content
            content="The Company may use Personal Data for the following purposes:"
            />
            <List
            list={[
            {head: "To provide and maintain our Service",content:", including to monitor the usage of our Service."},
            {head: "To manage Your Account:",content:" to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user."},
            {head: "For the performance of a contract:",content:" the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service."},
            {head: "To contact You:",content:" To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation."},
            {head: "To provide You",content:" with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information."},
            {head: "To manage Your requests:",content:" To attend and manage Your requests to Us."},
            {head: "For business transfers:",content:" We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred."},
            {head: "For other purposes:",content:" We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience."}
            ]}
            />
            <Content
            content="We may share Your personal information in the following situations:"
            />
            <List
            list={[
            {head: "With Service Providers:",content:" We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You."},
            {head: "For business transfers:",content:" We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company."},
            {head: "With Affiliates:",content:" We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us."},
            {head: "With business partners:",content:" We may share Your information with Our business partners to offer You certain products, services or promotions."},
            {head: "With other users:",content:" when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside."},
            {head: "With Your consent:",content:" We may disclose Your personal information for any other purpose with Your consent."}
            ]}
            />
            <HeadTwo
            head="Presence on Social Media"
            />
            <HeadThree
            head="Hubspot"
            />
            <Content
            content="To manage our customers, we use the CRM tool Hubspot. When you use any forms present in our website, your data will be transferred to Hubspot’s system. We manage your requests and analyze customer engagement using the services provided by Hubspot."
            />
            <HeadThree
            head="Google Analytics"
            />
            <Content
            content="To analyze the traffic of our website and the user behavior patterns we use Google Analytics. Google Analytics uses technologies that make the recognition of the user for the purpose of analyzing the user behavior. The website use information recorded by Google is, as a rule transferred to a Google server in the United States, where it is stored."
            />
            <HeadTwo
            head="Presence on Social Media"
            />
            <Content
            content="We have our presence on various social media platform to engage our potential customers with our news, product solutions and benefits. We use LinkedIn, Facebook (Meta), Instagram (Meta) and X (formerly known as Twitter). The data processing varies from platform to platform. In general the platforms analyze user behavior when you visit the respective platform or a website with integrated social media content (e.g. share/ like buttons or banners). Visiting our profiles on the platforms triggers numerous processing operations relevant to data protection."
            />
            <HeadTwo
            head="Retention of Your Personal Data"
            />
            <Content
            content="The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies."
            />
            <Content
            content="The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods."
            />
            <HeadTwo
            head="Transfer of Your Personal Data"
            />
            <Content
            content="Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction."
            />
            <Content
            content="Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer."
            />
            <Content
            content="The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information."
            />
            <HeadTwo
            head="Delete Your Personal Data"
            />
            <Content
            content="You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You."
            />
            <Content
            content="Our Service may give You the ability to delete certain information about You from within the Service."
            />
            <Content
            content="You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us."
            />
            <Content
            content="Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so."
            />
            <HeadTwo
            head="Disclosure of Your Personal Data"
            />
            <HeadThree
            head="Business Transactions"
            />
            <Content
            content="If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy."
            />
            <HeadThree
            head="Law enforcement"
            />
            <Content
            content="Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency)."
            />
            <HeadThree
            head="Other legal requirements"
            />
            <Content
            content="The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:"
            />
            <List
            list={[
            {content:"Comply with a legal obligation"},
            {content:"Protect and defend the rights or property of the Company"},
            {content:"Prevent or investigate possible wrongdoing in connection with the Service"},
            {content:"Protect the personal safety of Users of the Service or the public"},
            {content:"Protect against legal liability"}
            ]}
            />
            <HeadTwo
            head="Security of Your Personal Data"
            />
            <Content
            content="The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security."
            />
            <HeadOne
            head="Children's Privacy"
            />
            <Content
            content="Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers."
            />
            <Content
            content="If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information."
            />
            <HeadOne
            head="Links to Other Websites"
            />
            <Content
            content="Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit."
            />
            <Content
            content="We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services."
            />
            <HeadOne
            head="Changes to this Privacy Policy"
            />
            <Content
            content="Due to the further development of our website or the implementation of new technologies and features, it may become necessary to change this privacy policy. We reserve the right to make appropriate changes at any time."
            />
            <Content
            content="You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page."
            />
            <HeadOne
            head="Responsible Body for Data Protection"
            />
            <Content
            content="The person responsible pursuant to Art. 4 (7) GDPR is:"
            />
            <Content
            content="reLi Energy GmbH, Robert-Bosch-Straße 7, 64293, Darmstadt, Germany"
            />
            <Content
            content="dataprotection@relienergy.de"
            />
            </AdministrativePage>
            <FooterBar/>
        </>
    )
}

export default PrivacyPolicy;