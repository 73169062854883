import MetaData from "../../components/MetaData/MetaData";

import ErrorPage from "../../components/errors/errors";

const Error404 = () =>{
    return(
        <>
        <MetaData 
                title="Error 404"
                description=""
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <ErrorPage errorCode="404" errorMessage="Sorry, Page Not Found" errorContext="The page you request could not be found"/>
        </>
    )
}

export default Error404;