import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, ProductTiles } from '../../components/ProductSolution/ProductSolution';

import {    
    ProductBatteryAnalytics,
    ProductBatteryCommunication,
    ProductBatteryControl,
    ProductBatteryCostFunction,
    ProductBatteryOptimization,
    ProductBatterySimulation
} from './ProductsList';

import header from '../../assets/img/default-header.jpeg';

import "../page.css";

const ProjectDevelopers = () =>{
    return(
        <>
        <MetaData 
                title="Project Developers"
                description="Perform battery analysis while project development"
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar />
            <ImageHeader 
                image={header}
                head1="PROJECT DEVELOPERS"
                head2="Brainstorming for the optimal battery for your specific needs"
                content="Run extensive analysis of a battery to understand the operational impact while developing the battery project"
            />
            <ProductTiles 
                products={[
                    ProductBatterySimulation,
                    ProductBatteryCommunication,
                    ProductBatteryAnalytics,
                    ProductBatteryOptimization
                ]}
            />
            <FooterBar />
        </>
    )
}

export default ProjectDevelopers;