import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, Trail } from '../../components/ProductSolution/ProductSolution';

import header from '../../assets/img/default-header.jpeg';

import degradationmodel from '../../assets/img/products/BO-degradation-model.jpeg';
import optimizationmodel from '../../assets/img/products/BO-optimization-model.jpeg';
import realtimeoptimization from '../../assets/img/products/BO-real-time-optimization.jpeg';


import "../page.css";

const BatteryOptimization = () =>{
    let buttons=[
        {label:"Request Demo",link:"/resources/demo"}
    ]
    return (
        <>
            <MetaData 
                title="Battery Optimization Solutions - Maximizing Performance, Minimizing Degradation"
                description="Unlock the full potential of your battery with reLi's advanced optimization. Our cutting-edge Battery Degradation Model, coupled with a powerful Optimization Model, ensures you achieve peak performance while safeguarding battery health. "
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar />
            <ImageHeader 
                image={header}
                head1="BATTERY OPTIMIZATION"
                head2="Unleash the maximum performance of your battery with reLi’s optimization"
                content="Considering the impact on the battery while optimizing the battery asset for minimizing degradation of the battery while maximizing the profits."
                button={buttons}
            />
            <Trail
                head="Deep dive into battery optimization"
                contents={[
                    {image:degradationmodel,head:"Battery Degradation Model",content:"Field data is used to train reLi’s battery degradation model which creates a mathematical twin to the battery which enables the user to simulate the impact of utilizing the battery."},
                    {image:optimizationmodel,head:"Battery Optimization Model",content:"Identify the best battery operation schedule with reLi’s optimization model together with the degradation model maximizes the lifetime and pits of the battery asset."},
                    {image:realtimeoptimization,head:"Real-time Optimization",content:"reLi’s models are developed to run in a real-time environment which allows the model to collect feedback from the operation of the battery and adjust the operation to compensate for the deviation."},
                ]}
            />
            <FooterBar />
        </>
    )
}
export default BatteryOptimization;