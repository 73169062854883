import React from 'react';
import { Link } from 'react-router-dom';
import './AddressMap.css';

import ImageComp from './../ImageComp/ImageComp';

import ContactForm from './ContactForm';

const AddressMap = () =>{
    return (
        <div className='contacts'>
            <div className='address-map-contacts'>
                <ContactForm/>
            </div>
            <div className='address-map'>
                <div className='address-map-div'>
                    <ImageComp aspectratio="1.78">
                        <div className='address-map-map-content'>
                            <iframe className='address-map-map'
                                src="https://www.google.com/maps/place/TIZ+Darmstadt+GmbH/@49.8711804,8.6247866,17z/data=!3m1!4b1!4m6!3m5!1s0x47bd7099bdaa28c7:0x6979958a613a5b79!8m2!3d49.8711804!4d8.6247866!16s%2Fg%2F1th5632v?entry=ttu&g_ep=EgoyMDI0MTAyNy4wIKXMDSoASAFQAw%3D%3D"
                                allowfullscreen="" 
                                loading="lazy" 
                                width="100%"
                                height="100%"
                                referrerpolicy="no-referrer-when-downgrade"/>
                        </div>
                    </ImageComp>
                    <Link to="https://maps.app.goo.gl/dyLCzPQ4Tp72wF489" className='address-map-address-content'>
                        <div className='address-map-address-content-head'>Headquarters</div>
                        <div className='address-map-address-content-content'>Robert-Bosch-Straße 7</div>
                        <div className='address-map-address-content-content'>64293 Darmstadt</div>
                        <div className='address-map-address-content-content'>Germany</div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default AddressMap;