import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, ProductTiles } from '../../components/ProductSolution/ProductSolution';

import {    
    ProductBatteryAnalytics,
    ProductBatteryCommunication,
    ProductBatteryControl,
    ProductBatteryCostFunction,
    ProductBatteryOptimization,
    ProductBatterySimulation
} from './ProductsList';

import header from '../../assets/img/default-header.jpeg';

import "../page.css";

const Develop = () =>{
    return(
        <>
        <MetaData 
                title="Develop"
                description="Planning your investment for a battery asset"
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar />
            <ImageHeader 
                image={header}
                head1="DEVELOP PHASE"
                head2="Planning your investment for a battery asset"
                content="Determining the best battery solution for your use case by running exhaustive analysis with our battery database"
            />
            <ProductTiles 
                products={[
                    ProductBatterySimulation,
                    ProductBatteryCommunication
                ]}
            />
            <FooterBar />
        </>
    )
}

export default Develop;