import optimizationicon from "./../../assets/icons/navbar/Optimization.svg";
import costfunctionicon from "./../../assets/icons/navbar/Cost-Function.svg";
import controlicon from "./../../assets/icons/navbar/Control.svg";
import analyticsicon from "./../../assets/icons/navbar/Analytics.svg";
import simulationtoolicon from "./../../assets/icons/navbar/Battery-Simulation-Tool.svg";
import communicationicon from "./../../assets/icons/navbar/Communication.svg";

const ProductBatteryAnalytics = {
    link: "/products/battery-analytics", 
    head: "Battery Insights/Analytics", 
    content:"Estimating the battery’s real-time parameters using reLi’s insights, enables battery asset owners to manage, operate and maintain the battery energy storage system for improved performance and lifetime.", 
    icon: analyticsicon,
}
const ProductBatteryOptimization = {
    link: "/products/battery-optimization", 
    head: "Battery Optimization", 
    content:"Considering the impact on the battery while optimizing the battery asset for minimizing degradation of the battery while maximizing the profits.", 
    icon: optimizationicon,
}
const ProductBatterySimulation = {
    link: "/products/battery-simulation-tool", 
    head: "Battery Simulation Tool", 
    content:"reLi’s simulation tool provides a deep understanding of the battery’s operation and benefits for a use case through a detailed techno-economic analysis that gives investors the confidence of investing on the right battery energy storage.", 
    icon: simulationtoolicon,
}
const ProductBatteryCostFunction = {
    link: "/products/battery-cost-function", 
    head: "Battery Cost Function", 
    content:"Determining battery's marginal cost for each cycle type guarantees that every time you use your battery, it's a financially sound decision. Never worry about unprofitable use again – we've got your battery management covered.", 
    icon: costfunctionicon,
}
const ProductBatteryCommunication = {
    link: "/products/battery-communication", 
    head: "Battery Communication", 
    content:"Practically all modern devices and systems require interfaces and bus systems to exchange data, reLi’s strong embedded platform and experienced engineers have the capability to integrate battery energy storage in any complex energy system by mastering in various communication protocols.", 
    icon: communicationicon,
}
const ProductBatteryControl = {
    link: "/products/battery-control", 
    head: "Battery Control", 
    content:"Remote management of batteries open a wide variety of features like operational control methods for efficient and safely operation, over the air software updates, debugging operational issues and building new features.", 
    icon: controlicon,
}

export {
    ProductBatteryAnalytics,
    ProductBatteryCommunication,
    ProductBatteryControl,
    ProductBatteryCostFunction,
    ProductBatteryOptimization,
    ProductBatterySimulation
}