import React, { useState } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import './DemoForm.css'

const DemoForm = () => {
  const [formData, setFormData] = useState({ firstname: '', lastname: '', email: '', company: '', jobtitle: '', message: '',});
  const [phone, setPhone] = useState('');
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const hubspotFormId = '9e34a329-ce65-45f8-b4fb-ef8b9b4d4027';
    const hubspotPortalId = '25674141';

    fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fields: [
          {name: 'firstname', value: formData.firstname,},
          {name: 'lastname', value: formData.lastname,},
          {name: 'email', value: formData.email,},
          {name: 'mobilephone', value: phone,},
          {name: 'company', value: formData.company,},
          {name: 'jobtitle', value: formData.jobtitle,},
          {name: 'message', value: formData.message,},
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFormData({ firstname: '', lastname: '', email: '', company: '', jobtitle: '', message: ''});
        setPhone('');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className='Demo-form'>
      <div className='Demo-form-context head3'>Request a Trial</div>
        <form onSubmit={handleSubmit}>
            <div className='Demo-form-form'>
                <div className='Demo-form-form-line'>
                    <div className='Demo-form-form-field'>
                        <div className='Demo-form-label content-small'> First Name* </div>
                        <input className='Demo-form-input content-form'
                        type="text"
                        name="firstname"
                        value={formData.firstname}
                        onChange={handleChange}
                        required
                        />
                    </div>
                    <div className='Demo-form-form-field'>
                        <div className='Demo-form-label content-small'> Last Name* </div>
                        <input className='Demo-form-input content-form'
                        type="text"
                        name="lastname"
                        value={formData.lastname}
                        onChange={handleChange}
                        required
                        />
                    </div>
                </div>
                <div className='Demo-form-form-line'>
                    <div className='Demo-form-form-field'>
                        <div className='Demo-form-label content-small'> Email* </div>
                        <input className='Demo-form-input content-form'
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        />
                    </div>
                </div>
                <div className='Demo-form-form-line'>
                    <div className='Demo-form-form-field'>
                        <div className='Demo-form-label content-small'> Phone Number </div>
                        <PhoneInput className='Demo-form-input content-form'
                        international
                        defaultCountry="DE"
                        value={phone}
                        onChange={setPhone}
                        />
                    </div>
                </div>
                <div className='Demo-form-form-line'>
                    <div className='Demo-form-form-field content-small'>
                        <div className='Demo-form-label'> Company Name* </div>
                        <input className='Demo-form-input content-form'
                        type="text"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                        required
                        />
                    </div>
                    <div className='Demo-form-form-field'>
                        <div className='Demo-form-label content-small'> Job Title* </div>
                        <input className='Demo-form-input content-form'
                        type="text"
                        name="jobtitle"
                        value={formData.jobtitle}
                        onChange={handleChange}
                        required
                        />
                    </div>
                </div>
                <div className='Demo-form-form-line'>
                    <div className='Demo-form-form-field'>
                        <div className='Demo-form-label content-small'> Message* </div>
                        <textarea className='Demo-form-input content-form'
                        type="text"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        rows="5"
                        required
                        />
                    </div>
                </div>
                <div className='Demo-form-form-line Demo-form-form-line-button'>
                    <button className='Demo-form-form-button-submit button button-primary' type="submit">Request</button>
                </div>
            </div>
        </form>
      <div className='Demo-form-disclaimer content-tc'>reLi Energy GmbH will exclusively utilize the information provided here in strict adherence to the Privacy Policy.</div>
    </div>
  );
};

export default DemoForm;
