import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, Trail } from '../../components/ProductSolution/ProductSolution';

import header from '../../assets/img/default-header.jpeg';

import simulationtool from '../../assets/img/products/BST-simulation-tool.jpeg';
import kpi from '../../assets/img/products/BST-KPI.jpeg';
import finaicial from '../../assets/img/products/BST-Financial.jpeg';

import "../page.css";

const BatterySimulationTool = () =>{
    let buttons=[
        {label:"Request Demo",link:"/resources/demo"}
    ]
    return (
        <>
            <MetaData 
                title="Battery Simulation Tool - In-Depth Operational Analysis"
                description="Dive into the world of battery performance analysis with reLi's simulation tool. Gain comprehensive insights into real-time battery operations through detailed techno-economic analysis. Make confident investment decisions in battery energy storage with our advanced simulation capabilities."
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar />
            <ImageHeader 
                image={header}
                head1="BATTERY SIMULATION TOOL"
                head2="Extensive analysis to simulate a real-time battery operation"
                content="reLi’s simulation tool provides a deep understanding of the battery’s operation and benefits for a use case through a detailed techno-economic analysis that gives investors the confidence of investing on the right battery energy storage."
                button={buttons}
            />
            <Trail
                head="Deep dive into battery simulation tool"
                contents={[
                    {image:simulationtool,head:"Simulation Process",content:"Input your project details, from location to renewable resources, load, and battery info, customizing your simulation's foundation. Launch the simulation to see your data come to life, running through reLi's advanced algorithms for realistic, actionable insights."},
                    {image:finaicial,head:"Battery Sizing and Financial Analysis",content:"Find the best battery size for your needs, ensuring efficiency. Get clear figures on payback time, NPV, and potential earnings, helping you see the financial benefits."},
                    {image:kpi,head:"Operational Insights and KPIs",content:"Discover how long your system will last and the benefits of choosing reLi over standard options and check key indicators to measure how well your energy strategy is working, guiding you towards better energy management."},
                ]}
            />
            <FooterBar />
        </>
    )
}
export default BatterySimulationTool;