import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, ProductTiles } from '../../components/ProductSolution/ProductSolution';

import {    
    ProductBatteryAnalytics,
    ProductBatteryCommunication,
    ProductBatteryControl,
    ProductBatteryCostFunction,
    ProductBatteryOptimization,
    ProductBatterySimulation
} from './ProductsList';

import header from '../../assets/img/default-header.jpeg';

import "../page.css";

const IPP = () =>{
    return(
        <>
        <MetaData 
                title="IPP"
                description="Effectively monitor and maintain your battery asset"
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar />
            <ImageHeader 
                image={header}
                head1="IPP"
                head2="Make sure that your battery asset is performing at its best"
                content="Managing your battery asset to highest availability with monitoring and preventive maintenance from field measurement data"
            />
            <ProductTiles 
                products={[
                    ProductBatterySimulation,
                    ProductBatteryAnalytics,
                    ProductBatteryOptimization,
                    ProductBatteryControl
                ]}
            />
            <FooterBar />
        </>
    )
}

export default IPP;