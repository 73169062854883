import React from "react";

const ImageComp = ({aspectratio,children}) => {
    return (
        <>
            <div Style={`padding-top:${100/aspectratio}%; overflow:hidden; position:relative; width: 100%`}>{children}</div>
        </>
    )
}

export default ImageComp;