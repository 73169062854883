import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";
import { SmallImageHeader, PageTiles } from "../../components/General/General";

import resourcesheader from '../../assets/img/resources-header.jpeg';
import newsheader from '../../assets/img/news-header.jpeg';
import articlesheader from '../../assets/img/articles-header.jpeg';
import postcasheader from '../../assets/img/podcast-header.jpeg';
import awartdsheader from '../../assets/img/awards-header.jpeg';
import demoheader from '../../assets/img/demo-header.jpeg';

function Resources() {
    let tiles = [
        {
            img:newsheader,
            head:"News",
            content:"Read the latest news about reLi",
            link:'/resources/news/'
        },
        {
            img:articlesheader,
            head:"Articles",
            content:"Read the articles from reLi",
            link:'/resources/articles/'
        },
        {
            img:postcasheader,
            head:"Podcast",
            content:"Listen from the members of reLi",
            link:'/resources/podcast/'
        },
        {
            img:awartdsheader,
            head:"Awards",
            content:"Know more about reLi's recognitions",
            link:'/resources/awards/'
        },
        {
            img:demoheader,
            head:"Try our solution",
            content:"Experience how reLi's solution maximizes battery profitability and lifetime",
            link:'/resources/demo/'
        },
    ]
    return (
        <>
            <MetaData 
                title="Resources: Learn more about reLi Energy"
                description="Dive into a wealth of knowledge on reLi's Resources page. Explore our awards, news, podcasts, request a demo for hands-on experience and stay updated with the latest news."
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <SmallImageHeader
                head1='Resources'
                head2='Know more about reLi'
                image={resourcesheader}
            />
            <PageTiles
                tiles={tiles}
            />
            <FooterBar/>
        </>
    );
}

export default Resources;