import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, Trail } from '../../components/ProductSolution/ProductSolution';

import header from '../../assets/img/default-header.jpeg';

import degradationmodel from '../../assets/img/products/BCF-degradation-model.jpeg';
import costfunction from '../../assets/img/products/BCF-cost-function.jpeg';
import modelintegration from '../../assets/img/products/BCF-model-integration.jpeg';

import "../page.css";

const BatteryCostFunction = () =>{
    let buttons=[
        {label:"Request Demo",link:"/resources/demo"}
    ]
    return (
        <>
            <MetaData 
                title="Battery Cost Function - Optimize Financial Decisions"
                description="Maximize financial gains and eliminate uncertainties in battery use with reLi's cost function. Prioritize battery health, ensuring longevity and sustainability in every decision you make. Our equation, derived from the battery degradation model, ensures every cycle is a financially sound decision. Integrate with third-party models for the best economic battery schedule."
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar />
            <ImageHeader 
                image={header}
                head1="BATTERY COST FUNCTION"
                head2="Price your battery utilization with reLi’s cost function"
                content="Determining battery's marginal cost for each cycle type guarantees that every time you use your battery, it's a financially sound decision. Never worry about unprofitable use again – we've got your battery management covered."
                button={buttons}
            />
            <Trail
                head="Deep dive into battery cost function"
                contents={[
                    {image:degradationmodel,head:"Battery Degradation Model",content:"Field data is used to train reLi’s battery degradation model which creates a mathematical twin to the battery which enables the user to simulate the impact of utilizing the battery."},
                    {image:costfunction,head:"Battery Cost Function",content:"An equation derived from the battery degradation model that can be used to determine the cost of utilization of the battery for different battery cycle parameters."},
                    {image:modelintegration,head:"Integration to 3rd part Models",content:"Algorithmic trading models and optimization models can easily include the cost function as part of the models to allow the models to determine the best economic battery schedule. "},
                ]}
            />
            <FooterBar />
        </>
    )
}
export default BatteryCostFunction;