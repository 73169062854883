import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";
import NewsLetter from "../../components/NewsLetter/NewsLetter";

import { ImageHeader, DivisionBlock, ImageContentBlock } from "../../components/General/General";

import DefaultHeader from '../../assets/img/default-header.jpeg';

import Trader from '../../assets/img/trader-battery.jpeg';
import Asset from '../../assets/img/asset-battery.jpeg';
import Utility from '../../assets/img/utility-battery.jpeg';
import BatteryOptimization from '../../assets/img/battery-optimization.jpeg'

import lifetime from '../../assets/icons/lifetime.svg';
import savings from '../../assets/icons/savings.svg';
import emissions from '../../assets/icons/emissions.svg';

function Home() {
    let buttons=[
        {label:"Request Demo",link:"/resources/demo"}
    ]
    return (
        <>
            <MetaData 
                title="reLi Energy: Unlocking Batteries’ Full Potential - Optimize energy storage performance, extend lifetime, maximize profits"
                description="Explore the Future of Energy Storage with reLi Energy – Pioneers in Green Battery Technology. Unlock the power of innovative battery solutions and cutting-edge battery analytics and software. Optimize energy performance, extend battery lifetime, and embrace a sustainable energy future. Join us on the journey to efficient, eco-friendly energy storage."
                image={DefaultHeader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <ImageHeader 
                head1="Unlocking Batteries’ Full Potential"
                head2="Optimize your battery asset for a strategic operation that extends lifetime and increases profits"
                image={DefaultHeader}
                button={buttons}
            />
            <DivisionBlock
                head="What is Our Customers Benefit?"
                contents={[
                    {content:"up to 35% improved battery lifetime",icon:lifetime},
                    {content:"up to 70% increased profits/savings",icon:savings},
                    {content:"up to 35% reduced environmental impact",icon:emissions}
                ]}
            />
            <ImageContentBlock
                head="Why Battery Optimisation?"
                content="Not optimising your battery means leaving revenue on the table, essentially losing money. A lengthier battery lifespan translates to consistently higher usable capacity, resulting in increased revenues and diminished battery waste making it a sustainable, economic and environmental choice."
                image={BatteryOptimization}
                inverse={true}
                mobileimage={true}
            />
            <DivisionBlock
                head="Who benefits from reLi?"
                contents={[
                    {head:"Energy Traders",image:Trader,link:"/solutions/energy-traders"},
                    {head:"Asset Managers",image:Asset,link:"/solutions/asset-managers"},
                    {head:"Utilities",image:Utility,link:"/solutions/utilities"}
                ]}
            />
            <NewsLetter/>
            <FooterBar/>
        </>
    )
}

export default Home;