import React from "react";

import "./errors.css"
import { Link } from "react-router-dom";

const ErrorPage = ({errorCode, errorMessage, errorContext}) =>{
    return (
        <>
            <div className='error-div'>
                <div className='error-code'>{errorCode}</div>
                <div className='error-message'>{errorMessage}</div>
                <div className='error-context'>{errorContext}</div>
                <div className='error-button'><Link to='/' className="button button-primary">Go Back to Home</Link></div>
                
            </div>
        </>
    )
}

export default ErrorPage;