import React, { useState } from 'react';

import './NewsLetter.css'

const NewsLetter = () => {
  const [formData, setFormData] = useState({name: '',email: '',});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const hubspotFormId = 'bd89e77e-b80b-46d2-9d4a-885ffff7d14d';
    const hubspotPortalId = '25674141';

    fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fields: [
          {name: 'firstname', value: formData.name,},
          {name: 'email', value: formData.email,},
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFormData({ name: '', email: '' });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className='news-letter'>
      <div className='news-letter-context head3'>Stay up to date with our Newsletter!</div>
      <form onSubmit={handleSubmit} className='news-letter-form'>
        <div className='news-letter-form-field'>
          <div className='news-letter-label content-small'> Name </div>
          <input className='news-letter-input content-form'
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className='news-letter-form-field'>
          <div className='news-letter-label content-small'> Email </div>
          <input className='news-letter-input content-form'
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className='news-letter-form-field'>
          <button className='news-letter-form-button button button-primary' type="submit">Subscribe</button>
        </div>
      </form>
      <div className='news-letter-disclaimer content-tc'>reLi Energy GmbH will exclusively utilize the information provided here in strict adherence to the Privacy Policy.</div>
    </div>
  );
};

export default NewsLetter;
