import React, {useState, useRef} from "react";
import { ReactDOM, Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

import './ProductSolution.css';

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      if (domRef.current) {
        observer.observe(domRef.current);
      }
  
      return () => {
        if (domRef.current) {
          observer.unobserve(domRef.current);
        }
      };
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
}

const ImageHeader = ({image,head1,head2,content,button}) =>{
    let buttonlist=[]
    if(button)
        buttonlist.push(button.map((button,index)=>{return <Link to={button.link} className="ps-image-header-button button button-primary">{button.label}</Link>}))
    return(
        <div className="ps-image-header" style={{backgroundImage:`linear-gradient(0.25turn, rgba(255, 255, 255, 1), rgba(21, 54, 138, 0.1)),url(${image})`}}>
            <div className="ps-image-block ps-image-block-head small-head">{head1}</div>
            <div className="ps-image-block ps-image-block-head head1">{head2}</div>
            <div className="ps-image-block ps-image-block-content content-sub">{content}</div>
            <div className="ps-image-actions">{buttonlist}</div>
        </div>
    )
}

function tolist(contents) {
    let contentlist =[]
    contentlist.push(contents.map((content,index)=>{return <li>{content}</li>}))
    return (
        <ul className='ps-trail-block-content-subtext-list'>
            {contentlist}
        </ul>
    )
}

function contentsubroutine(content,index) {
    let contentimage = content.image?content.image:'';
    let contentcontent = Array.isArray(content.content)?tolist(content.content):content.content;
    if(index%2 === 0)
        return(
            <FadeInSection>
            <div className="ps-trail-block-content">
                <div className="ps-trail-block-content-img ps-right-indent">
                    <img src={contentimage} alt={content.head}/>
                </div>
                <div className="ps-trail-block-content-text">
                    {content.content?<div className="ps-trail-block-content-subtext head3">{content.head}</div>:<div className="ps-trail-block-content-subtext head-only ps-left-indent">{content.head}</div>}
                    {content.content?<div className="ps-trail-block-content-subtext ps-trail-block-content-subtext-content content">{contentcontent}</div>:''}
                    {content.button?<div className="ps-trail-block-content-subtext ps-trail-block-content-subtext-button"><Link to={content.button.link} className="ps-trail-block-content-subtext-button button button-primary">{content.button.label}</Link></div>:''}
                </div>
            </div>
            </FadeInSection>
        )
    return(
        <FadeInSection>
        <div className="ps-trail-block-content ps-mobile-indent">
            <div className="ps-trail-block-content-text">
                    {content.content?<div className="ps-trail-block-content-subtext head3">{content.head}</div>:<div className="ps-trail-block-content-subtext head-only ps-right-indent">{content.head}</div>}
                    {content.content?<div className="ps-trail-block-content-subtext ps-trail-block-content-subtext-content content">{contentcontent}</div>:''}
                    {content.button?<div className="ps-trail-block-content-subtext ps-trail-block-content-subtext-button"><Link to={content.button.link} className="ps-trail-block-content-subtext-button button button-primary">{content.button.label}</Link></div>:''}
            </div>
            <div className="ps-trail-block-content-img ps-left-indent">
                <img src={contentimage} alt={content.head}/>
            </div>
        </div>
        </FadeInSection>
    )
}

const Trail = ({head,contents}) => {
    let contentlist = []
    if(contents)
        contentlist.push(contents.map((content,index)=>{return contentsubroutine(content,index)}))
    return(
        <div className="ps-trail">
            <div className="ps-trail-block head2-5">{head}</div>
            {contentlist}
        </div>
    )
}

const icontile = (tile) =>{
    return (
        <div className='ps-icon-tile head4'>
            <ReactSVG src={tile.icon} className='ps-icon-tile-icon'/>
            <div className='ps-icon-tile-head head4'>{tile.content}</div>
        </div>
    )
}

const IconTiles = ({head,tiles}) =>{
    let tileslist = []
    tileslist.push(tiles.map((tile,index)=>{return icontile(tile)}))
    return(
        <div className='ps-icon-tiles'>
            <div className='ps-icon-tiles-head head3'>{head}</div>
            <div className='ps-icon-tiles-div'>{tileslist}</div>
        </div>
    )
}

const ProductTile = (product) =>{
    return (
        <div className='ps-product-tile'>
            <div className='ps-product-tile-head head4'><img src={product.icon} className='ps-icon-tile-icon'/>{product.head} </div>
            <div className='ps-product-tile-content content-small'>{product.content}</div>
            <div className='ps-product-tile-link content-small'><Link to={product.link} className='ps-product-tile-button'>Learn More...</Link></div>
        </div>
    )
}

const ProductTiles = ({products}) =>{
    let productlist = [];
    productlist.push(products.map((product,index)=>{return ProductTile(product)}))
    return (
        <div className='ps-product-tiles'>
            <div className='ps-product-tiles-head head3'>Suggested Products</div>
            <div className='ps-product-tiles-div'>{productlist}</div>
        </div>
    )
}

export {ImageHeader, Trail, IconTiles, ProductTiles};