import React from "react";

import NavBar from "../../../components/NavBar/NavBar";
import FooterBar from "../../../components/FooterBar/FooterBar";
import MetaData from "../../../components/MetaData/MetaData";
import { SmallImageHeader, PageTiles } from "../../../components/General/General";

import newsheader from '../../../assets/img/news-header.jpeg'
import pvEuropeHeader from '../../../assets/img/pv-news-pic.jpg'
import pvEuropeCostHeader from '../../../assets/img/cost-header.jpg'

function News() {
    let tiles = [
        {
            head:"Cost functions and strategies for long-term gains",
            content:"pv Europe",
            date:'Nov 2024',
            link:'https://www.pveurope.eu/solar-storage/energy-storage-optimizing-energy-storage-cost-functions-and-strategies-long-term',
            img: pvEuropeCostHeader ,
        },
        {
            head:"Free Electrons announces reLi Energy as startup of the year in 2024",
            content:"EDP",
            date:'Oct 2024',
            link:'https://www.edp.com/en/FreeElectronsannouncesreLiEnergy2024edition',
            img: 'https://www.edp.com/sites/default/files/2024-10/startup%20do%20ano%20e%20pr%C3%A9mio%20akimoto.jpg' ,
        },
        {
            head:"Maximizing energy storage efficiency",
            content:"pv Europe",
            date:'Apr 2024',
            link:'https://www.pveurope.eu/solar-storage/guest-article-maximizing-energy-storage-efficiency',
            img: pvEuropeHeader ,

        },
        {
            head:"Optimising energy storage: A synergistic approach for enhanced efficiency and profitability",
            content:"Energy Storage News",
            date:'Mar 2024',
            link:'https://www.energy-storage.news/optimisation-energy-storage-enhanced-efficiency-and-profitability/',
            img:'https://www.energy-storage.news/wp-content/uploads/2024/03/reLi-Energy-at-Enlit-Europe-via-LinkedIn.jpg'

        },
        {
            head:"Darmstadt-based startup extends the duration of energy storage",
            content:"Echo",
            date:'Jan 2024',
            link:'https://www.echo-online.de/lokales/suedhessen/darmstaedter-start-up-holt-laenger-strom-aus-batterien-3207985',
            img:'https://img.echo-online.de/lokales/suedhessen/m9yrsc-Reli-3.jpg/alternates/WIDE_960/Reli-3.jpg'
        },
        {
            head:"Improving battery performance and lifespan for green energy storage",
            content:"PV Magazine",
            date:'Nov 2023',
            link:'https://www.pv-magazine.de/2023/11/08/verbesserung-der-batterieleistung-und-lebensdauer-fuer-die-speicherung-von-gruenstrom/?utm_source=dlvr.it&utm_medium=linkedin',
            img:'https://www.pv-magazine.de/wp-content/uploads/sites/4/2023/05/04032_Grossshirma_I_Tesvolt-e1691410125100-1024x541.jpg'
        },
        {
            head:"5 Questions to reLi",
            content:"Station Frankfurt",
            date:'Aug 2023',
            link:'https://station-frankfurt.de/2023/08/14/5-fragen-an-reli/',
            img:'https://station-frankfurt.de/wp-content/uploads/2023/08/reli.png'
        },
        {
            head:"7 women-led climatetech startups bringing hope to Europe",
            content:"EU-Startups",
            date:'May 2023',
            link:'https://www.eu-startups.com/2023/05/7-women-led-climatetech-startups-bringing-hope-to-europe/',
            img:'https://www.eu-startups.com/wp-content/uploads/2023/05/front-view-business-women-posing-1.jpg'
        },
        {
            head:"Start-up funding: 21 Hessian start-ups receive push! funding recommendations",
            content:"StartHub Hessen",
            date:'May 2023',
            link:'https://www.starthub-hessen.de/aktuelles/start-up-f%C3%B6rderung-21-hessische-start-ups-erhalten-push-f%C3%B6rderempfehlungen/',
            img:'https://www.starthub-hessen.de/media/images/push_bild.2e16d0ba.fill-1200x630.format-webp.webp'
        },
        {
            head:" Italian-Indian-led startup in Darmstadt wins prize at International Founders Award",
            content:"Invest in Hessen",
            date:'March 2022',
            link:'https://www.invest-in-hessen.de/news/italian-indian-led-startup-in-darmstadt-wins-prize-at-international-founders-award-2023',
            img:'https://img.hessen-agentur.de/image/redaktion.hessen-agentur.de/newsImage/2023/44591__35A1188_1.JPG=s1200'
        },
        {
            head:"EIT InnoEnergy Master School alumni share their most recent start-up successes!",
            content:"EIT InnoEnergy",
            date:'March 2023',
            link:'https://www.innoenergy.com/news-events/eit-innoenergy-master-school-alums-share-their-most-recent-start-up-successes/',
            img:'https://eit.innoenergy.com/wp-content/uploads/2023/02/Capture.jpg'
        },
        {
            head:"Start-ups for the energy transition at a glance",
            content:"PV Magazine",
            date:'Nov 2022',
            link:'https://www.pv-magazine.de/unternehmensmeldungen/start-ups-fuer-die-energiewende-im-blick/',
            img:'https://www.pv-magazine.de/wp-content/themes/pvmagazine/_/img/pv-magazine-logo.png'
        },
    ]
    return (
        <>
            <MetaData 
                title="Latest News on Battery Optimization and Green Energy"
                description="Get the latest updates and insights from reLi. Explore news articles covering battery optimization, new technologies, and sustainable energy trends for a greener future."
                image={newsheader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <SmallImageHeader
                image={newsheader}
                head1='News'
                head2='Read the latest news about reLi'
            />
            <PageTiles
                tiles={tiles}
                defaultimg={newsheader}
            />
            <FooterBar/>
        </>
    );
}

export default News;