import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, AdministrativePage, HeadOne, HeadTwo, HeadThree, Content, List } from "../../components/Administrative/Administrative";

const Imprint = () =>{
    return(
        <>
            <MetaData 
                title="Imprint - reLi Energy"
                description=""
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <ImageHeader
             head1="Imprint"
            />
            <AdministrativePage>
                <HeadOne
                head="Disclosures in accordance with §5 TMG"
                />
                <Content
                content="reLi Energy GmbH, Robert-Bosch-Straße 7, 64293, Darmstadt, Germany"
                />
                <HeadThree
                head="Managing Director:"
                />
                <Content
                content="Ms. Laura Laringe"
                />
                <HeadThree
                head="Contact:"
                />
                <Content
                content="info@relienergy.de"
                />
                <HeadThree
                head="VAT number:"
                />
                <Content
                content="Sales tax identification number according to §27 a sales tax law: DE357790599"
                />
                <HeadThree
                head="Register entry:"
                />
                <Content
                content="Registry jurisdiction: Darmstadt"
                />
                <Content
                content="Registry number: HRB 104322"
                />
                <HeadOne
                head="Disclaimer"
                />
                <HeadTwo
                head="Liability for Content"
                />
                <Content
                content="The contents of our pages were created with great care. However, we cannot guarantee that the content is correct, complete or up-to-date. As a service provider, we are responsible for our own content on these pages in accordance with general laws in accordance with Section 7 Paragraph 1 TMG. However, according to Sections 8 to 10 TMG, as a service provider we are not obliged to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information in accordance with general law remain unaffected. However, liability in this regard is only possible from the time of knowledge of a specific legal violation. If we become aware of any corresponding legal violations, we will immediately remove this content."
                />
                <HeadTwo
                head="Copyright"
                />
                <Content
                content="The content and works published on this website are subject to German copyright law. Reproduction, processing, distribution and any kind of exploitation outside the limits of copyright law require the prior written consent of the respective author or creator."
                />

                <HeadTwo
                head="Data Protection"
                />
                <Content
                content="It is generally possible to use our website without providing any personal data. As far as personal data (e.g. name, address or e-mail address) is collected on our pages, this is always done on a voluntary basis - as far as possible. We will not pass on your data to third parties without your express consent. We would also like to point out that data transmission over the Internet (such as when communicating via email) can have security gaps. Complete protection of data against access by third parties is not possible. We hereby expressly object to the use by third parties of contact details published as part of the imprint obligation to send unsolicited advertising and information materials. The operators of these pages expressly reserve the right to take legal action in the event of unsolicited advertising information being sent, such as spam emails."
                />
            </AdministrativePage>
            <FooterBar/>
        </>
    )
}

export default Imprint;