import React from "react";

import NavBar from "../../../components/NavBar/NavBar";
import FooterBar from "../../../components/FooterBar/FooterBar";
import MetaData from "../../../components/MetaData/MetaData";

import { ImageHeader, ArticlePage, ArticleShare,
    ArticleTitle, ArticleSection, ArticleContents, ArticleImage,
    ArticleQuote, ArticleExample, ArticleList } from "../../../components/ArticleData/ArticleData";

import DispatchControlHeader from "../../../assets/img/dispatch-control-header.jpeg";

import OptimizationControl from "../../../assets/img/reli-system.jpeg";
import CostFunction from "../../../assets/img/reli-cost-function.jpeg";

const DisPatchAndControl = () =>{
    return (
        <>
            <MetaData 
                title="Navigating the Future of Energy Management with reLi: Dispatch and Control Unveiled"
                description="Explore the intricate world of energy management with reLi's cutting-edge Dispatch and Control system. Learn how our innovative approach seamlessly integrates with the Optimization Algorithm, providing users with unprecedented control over battery performance and longevity."
                image={DispatchControlHeader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar />
            <ImageHeader
                head1="Dispatch and Control"
                image={DispatchControlHeader}
            />
            <ArticlePage>
                <ArticleTitle
                    head="Take control of your battery for optimal performance"
                />
                <ArticleContents
                    contents={[
                        "In the ever-evolving landscape of energy management, the quest for efficient and sustainable energy storage solutions has become paramount. With the growing adoption of renewable energy sources, the demand for innovative approaches to maximize the performance and longevity of energy storage assets has never been greater.",
                        "Enter reLi, the pioneering startup at the forefront of this transformative wave. In a previous article, we unveiled the inner workings of our reLi's Optimization Algorithm, a groundbreaking solution designed to revolutionize the energy storage industry. It's a game-changing technology that balances profitability and battery lifespan, using meticulous data collection and advanced modeling techniques to achieve this synergy.",
                        "Today, we're here to delve into another crucial aspect of energy management: dispatch and control, a process based on the results we've obtained with our optimization algorithm. If you're curious about the specifics of our algorithm and its profound implications, we encourage you to explore our earlier article. Here, we will build upon those foundations to show you how reLi's dispatch and control system takes the insights and knowledge derived from the optimization algorithm and translates them into real-world, hands-on energy management strategies.",
                        "As can be seen in the previous article, reLi's optimization algorithm allows to increase battery longevity, enhanced profitability while reducing de CO2 emissions. However, it is essential to recognize that the optimization algorithm generates numerical outputs and that another tool is needed to translate those numbers into actions. It is at this juncture that the dispatch and control functions step in, utilizing these numerical insights to control the battery for optimal operation."
                    ]}
                />
                <ArticleSection
                    head="Dispatch and control: what is it?"
                    content={"In the context of a battery, \"dispatch and control\" refers to the processes involved in managing the operation of a battery system. These processes are critical for optimizing the performance and efficiency of the battery. Dispatch involves making real-time decisions about how a battery is used to meet specific energy demands. It determines when and how the battery should charge or discharge based on various factors. Dispatch strategies aim to maximize the value of the battery system by using it in an optimal way. Control refers to actively managing the battery's state and operation. This includes monitoring and adjusting parameters like State of Charge (SoC), State of Health (SoH), charge/discharge rates, and operational set points to ensure the battery operates efficiently and safely."}
                    inverse={true}
                >
                    <ArticleContents
                        contents={[
                            "reLi offers two distinct ways to operate and use the optimization algorithm outputs to meet various energy management needs. The first approach involves direct control of the battery, enabling users to manage battery performance and operations either automatically or manually. The second method is the Battery Cost Function, tailored for traders and dispatchers, allowing for real-time insights and cost-effective decision-making. In the two following sections, we will delve into both of these approaches and explore how reLi empowers users to maximize the efficiency and longevity of their energy storage systems."
                        ]}
                    />
                </ArticleSection>
                <ArticleSection
                    head="Direct control of the battery"
                    content="reLi's Dispatch and Control tool is a cutting-edge embedded software solution, intelligently orchestrating battery operation within its optimal range through seamless cloud communication, effectively safeguarding against premature degradation and significantly enhancing battery lifespan, while being compatible with industry-standard embedded hardware. The tool has several features:"
                >
                    <ArticleImage 
                        image={OptimizationControl}
                        label="reLi's Optimization and Control"
                        portrait={true}
                    >
                        <ArticleList
                            contents={[
                                {head:"Automatic or Manual Operational Set Point: ",content:"Users can choose between automatic or manual operational set points, allowing for flexibility in managing battery performance based on specific requirements."},
                                {head:"Battery Asset Management and Optimization: ",content:"reLi offers comprehensive battery asset management and optimization features. It delivers an operational schedule that outlines precisely when and how to use the battery for maximum efficiency."},
                                {head:"Seamless Integration: ",content:"reLi's output can be connected directly to the user’s SCADA (Supervisory Control and Data Acquisition) system and/or inverter, enhancing control and monitoring efficiency. reLi provides the option for either user-controlled or automated battery operation."},
                                {head:"Performance and Safety Protection Hardware Layer: ",content:"The integration of a dedicated hardware layer between the battery and the Energy Management System (EMS) or inverter ensures heightened performance and safety. This additional layer secures battery operations, making them more reliable and efficient."},
                            ]}
                            numbered={true}
                        />
                    </ArticleImage>
                </ArticleSection>
                <ArticleSection
                    head="Battery Cost Function for Traders and Dispatchers"
                    content="The second way to give the optimizations outputs is the Battery Cost Function, particularly beneficial for traders and dispatchers. This feature can be seamlessly integrated with other algorithms, especially those employed by energy trading companies in the field of Frequency Containment Reserve (FCR)."
                    inverse={true}
                >
                    <ArticleContents 
                        contents={[
                            "By considering the long-term impact on battery health, it allows for more informed and sustainable energy trading decisions. reLi's Battery Cost Function allows them to assess the marginal cost of battery utilization through a customizable cost function, seamlessly integrating with their optimization tools to evaluate the impact of each operation on the battery. This functionality aids in optimizing the battery's longevity and performance, while providing real-time insights into the profit margin associated with the battery lifecycle. Here's how it works:"
                        ]}
                    />
                    <ArticleImage
                        image={CostFunction}
                        label="reLi's Battery Cost Function"
                        portrait={true}
                    >
                        <ArticleList
                            contents={[
                                {head:"Real-time Marginal Cost of Battery Utilization: ",content:"The cost function provides real-time information on the marginal cost of battery utilization. This enables traders and dispatchers to make decisions that are both cost-effective and performance-driven."},
                                {head:"Integration with Optimization Tools: ",content:"The cost function can be seamlessly integrated with optimization tools, allowing users to make data-driven decisions based on cost-effectiveness."},
                                {head:"APIs for Parameter Updates: ",content:"APIs (Application Programming Interfaces) are available to update parameters in the cost function, ensuring that it remains relevant and adaptable to changing conditions."},
                                {head:"Operational Optimization Tool: ",content:"The cost function serves as a valuable tool for traders and dispatchers, helping them optimize battery operations and taking control of the battery in an informed and optimal way."},
                            ]}
                            numbered={true}
                        />
                    </ArticleImage>
                </ArticleSection>
                <ArticleQuote
                    content="reLi offers crucial insights into how battery operation will impact the battery's lifespan and profitability"
                />
                <ArticleTitle
                    head="Conclusion"
                />
                <ArticleContents
                    contents={[
                        "In conclusion, reLi's Dispatch and Control feature has the potential to enhance operations significantly when seamlessly integrated with reLi's optimization capabilities. This versatile feature provides different customers with the flexibility to harness our optimization algorithm in two distinct ways.",
                        "On one hand, users can opt for direct control of the battery or provide a predefined operational schedule, allowing for precise management of battery performance. On the other hand, customers can employ a cost function to seamlessly integrate with their optimization system, offering crucial insights into how each operation will impact the battery's lifespan and profitability. This adaptability empowers users to tailor their approach to energy management, ensuring both efficiency and financial sustainability."
                    ]}
                />
                <ArticleShare
                    request="Request demo"
                    requestlink='/resources/demo'
                    link={encodeURIComponent(window.location.href)}
                />
            </ArticlePage>
            <FooterBar />
        </>
    )
};

export default DisPatchAndControl;