import React, { useState } from 'react';

import './ContactForm.css'

const ContactForm = () => {
  const [formData, setFormData] = useState({firstname: '', lastname: '', email: '', message:'',});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const hubspotFormId = '1005aee1-3424-4916-b234-133a4f50a40e';
    const hubspotPortalId = '25674141';

    fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fields: [
          {name: 'firstname', value: formData.firstname,},
          {name: 'lastname', value: formData.lastname,},
          {name: 'email', value: formData.email,},
          {name: 'message', value: formData.message,},
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFormData({ firstname: '', lastname: '', email: '', message:'', });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className='contact-form'>
      <div className='contact-form-context head3'>Contact Details</div>
        <form onSubmit={handleSubmit}>
            <div className='contact-form-form'>
                <div className='contact-form-form-line'>
                    <div className='contact-form-form-field'>
                        <div className='contact-form-label content-small'> First Name* </div>
                        <input className='contact-form-input content-form'
                        type="text"
                        name="firstname"
                        value={formData.firstname}
                        onChange={handleChange}
                        required
                        />
                    </div>
                    <div className='contact-form-form-field'>
                        <div className='contact-form-label content-small'> Last Name* </div>
                        <input className='contact-form-input content-form'
                        type="text"
                        name="lastname"
                        value={formData.lastname}
                        onChange={handleChange}
                        required
                        />
                    </div>
                </div>
                <div className='contact-form-form-line'>
                    <div className='contact-form-form-field'>
                        <div className='contact-form-label content-small'> Email* </div>
                        <input className='contact-form-input content-form'
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        />
                    </div>
                </div>
                <div className='contact-form-form-line'>
                    <div className='contact-form-form-field'>
                        <div className='contact-form-label content-small'> Message* </div>
                        <textarea className='contact-form-input content-form'
                        type="text"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        rows="5"
                        required
                        />
                    </div>
                </div>
                <div className='contact-form-form-line contact-form-form-line-button'>
                    <button className='contact-form-form-button-submit button button-primary' type="submit">Submit</button>
                </div>
            </div>
        </form>
      <div className='contact-form-disclaimer content-tc'>reLi Energy GmbH will exclusively utilize the information provided here in strict adherence to the Privacy Policy.</div>
    </div>
  );
};

export default ContactForm;
