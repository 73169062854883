import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader } from "../../components/General/General";

import AddressMap from "../../components/AddressMap/AddressMap";

import contactheader from  '../../assets/img/contact-header.jpeg';

function Contact() {
    return (
        <>
            <MetaData 
                title="Contact reLi Energy's team"
                description="Do you want to know more or be part of our network?"
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <ImageHeader 
                image={contactheader}
                head1='Contact Us'
                head2='Do you want to know more or be part of our network?'
            />
            <AddressMap/>
            <FooterBar/>
        </>
            )
}

export default Contact;