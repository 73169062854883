import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ScrollToTop from './helper/ScrollToTop';
import './index.css';

import pageRoutes from "./routes.js";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-6T3PWVEZZ6";
ReactGA.initialize(TRACKING_ID);

ReactGA.send({ hitType: "pageview", page: window.location.pathname});

const renderRoutes = (routes) => {
  return routes.map((route, index) => {
    const { path, component, routes: subRoutes } = route;
    if (subRoutes) {
      return (
        <Route  key={index} path={path}>
          <Route key={index} index element={component}/>
          {renderRoutes(subRoutes)}
        </Route>
      );
    }
    else {
      return (
        <Route key={index} path={path} element={component}/>
      );
    }
  });
};

const App = () =>{
  return(
    <Router>
      <ScrollToTop />
      <Routes>
        {renderRoutes(pageRoutes)}
      </Routes>
    </Router>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App/>
);
