import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, ProductTiles } from '../../components/ProductSolution/ProductSolution';

import header from '../../assets/img/default-header.jpeg';

import {    
    ProductBatteryAnalytics,
    ProductBatteryCommunication,
    ProductBatteryControl,
    ProductBatteryCostFunction,
    ProductBatteryOptimization,
    ProductBatterySimulation
} from './ProductsList';

import "../page.css";

const EnergyTraders = () =>{
    return(
        <>
        <MetaData 
                title="Energy Traders"
                description="Determine the correct marginal cost of your battery asset"
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar />
            <ImageHeader 
                image={header}
                head1="ENERGY TRADERS"
                head2="Understanding the inside battery of the battery in financial terms"
                content="Marketing the battery asset for different electricity services considering the impact on the battery and the associated marginal cost"
            />
            <ProductTiles 
                products={[
                    ProductBatteryAnalytics,
                    ProductBatteryOptimization,
                    ProductBatteryCostFunction
                ]}
            />
            <FooterBar />
        </>
    )
}

export default EnergyTraders;