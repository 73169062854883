import React from "react";

import NavBar from "../../../components/NavBar/NavBar";
import FooterBar from "../../../components/FooterBar/FooterBar";
import MetaData from "../../../components/MetaData/MetaData";
import { SmallImageHeader, PageTiles } from "../../../components/General/General";

import postcasheader from '../../../assets/img/podcast-header.jpeg';
import forestvalley from '../../../assets/img/forestvalley.jpg';

function Podcast() {
    let tiles = [
        {
            head:"Charging Ahead: Innovations in Battery Efficiency",
            content:"Forest Valley",
            date:'Jun 2024',
            link:'https://www.forestvalley.org/article/charging-ahead-innovations-in-battery-efficiency',
            img: forestvalley
        },
        {
            head:"DigitalValley goes HIGHEST - reLi",
            content:"DigitalValley",
            date:'Apr 2023',
            link:'https://open.spotify.com/episode/6o27ivlNXNyU1mUDEcVivG',
            img:'https://i.scdn.co/image/ab6765630000ba8a898ecef6c492d4250732f8c1'
        },
        {
            head:"A brain for your battery with reLi",
            content:"InnoStation",
            date:'Mar 2023',
            link:'https://open.spotify.com/episode/58Tsbl02wgUg2FHUBZbsC4',
            img:'https://i.scdn.co/image/ab6765630000ba8a16e053a8cd99c1c12ab629e5'
        },
    ]
    return (
        <>
            <MetaData 
                title="Listen In to reLi's Podcasts: Exploring Innovations in Battery Optimization"
                description="une into reLi's podcasts for engaging discussions on battery optimization, green energy, and the future of sustainable solutions."
                image={postcasheader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <SmallImageHeader
                image={postcasheader}
                head1='Podcast'
                head2='Listen from the members of reLi'
            />
            <PageTiles
                tiles={tiles}
                contain={true}
            />
            <FooterBar/>
        </>
    );
}

export default Podcast;