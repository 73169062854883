import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, AdministrativePage, HeadOne, HeadTwo, HeadThree, Content, List } from "../../components/Administrative/Administrative";

const TermsAndConditions = () =>{
    return(
        <>
            <MetaData 
                title="T&C - reLi Energy"
                description=""
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <ImageHeader
             head1="Terms and Conditions"
            />
            <AdministrativePage>
                <Content
                content="Last updated: November 10, 2023"
                />
                <Content
                content="These terms and conditions (“Agreement”) set forth the general terms and conditions of your use of the relibatteries.eu website (“Website” or “Service”) and any of its related products and services (collectively, “Services”). This Agreement is legally binding between you (“User”, “you” or “your”) and this Website operator (“Operator”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Website and Services. By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. You acknowledge that this Agreement is a contract between you and the Operator, even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services."
                />
                <HeadTwo
                head="Links to other resources"
                />
                <Content
                content="Although the Website and Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Website. Your linking to any other off-site resources is at your own risk."
                />
                <HeadTwo
                head="Intellectual property rights"
                />
                <Content
                content="“Intellectual Property Rights” means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of intellectual activity which subsist or will subsist now or in the future in any part of the world. This Agreement does not transfer to you any intellectual property owned by the Operator or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with the Operator. All trademarks, service marks, graphics and logos used in connection with the Website and Services, are trademarks or registered trademarks of the Operator or its licensors. Other trademarks, service marks, graphics and logos used in connection with the Website and Services may be the trademarks of other third parties. Your use of the Website and Services grants you no right or license to reproduce or otherwise use any of the Operator or third party trademarks."
                />
                <HeadTwo
                head="Changes and amendments"
                />
                <Content
                content="We reserve the right to modify this Agreement or its terms related to the Website and Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided."
                />
                <Content
                content="An updated version of this Agreement will be effective immediately upon the posting of the revised Agreement unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Agreement (or such other act specified at that time) will constitute your consent to those changes."
                />
                <HeadTwo
                head="Acceptance of these terms"
                />
                <Content
                content="You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Website and Services."
                />
                <HeadTwo
                head="Contacting us"
                />
                <Content
                content="If you have any questions, concerns, or complaints regarding this Agreement, we encourage you to contact us using the details below:"
                />
                <Content
                content="reLi Energy GmbH, Hilpertstraße 31, 64295, Darmstadt, Germany"
                />
                <Content
                content="dataprotection@relienergy.de"
                />
            </AdministrativePage>
            <FooterBar/>
        </>
    )
}

export default TermsAndConditions;