import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

import './CookieConsent.css'

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    const foreverExpiration = new Date('9999-12-31');
    setCookie("cookieConsent", true, { path: "/", expires: foreverExpiration  });
  };

  return (
    <div className="cookie-consent">
      <div className='cookie-consent-content'>
        We use cookies to enhance your user experience. By using our website,
        you agree to our use of cookies.{" "}
        <a href={"/privacy-policy"}>Learn more</a>
      </div>
      <div className='cookie-consent-button-div'>
        <button className='cookie-consent-button' onClick={giveCookieConsent}>Agree</button>
      </div>
    </div>
  );
};

export default CookieConsent;