import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, Trail } from '../../components/ProductSolution/ProductSolution';

import header from '../../assets/img/default-header.jpeg';

import communication from '../../assets/img/products/BCOM-communication.jpeg';

import "../page.css";

const BatteryCommunication = () =>{
    let buttons=[
        {label:"Request Demo",link:"/resources/demo"}
    ]
    return (
        <>
            <MetaData 
                title="Battery Communication - Unlock Seamless Connectivity for Your Battery Energy Storage"
                description="Integrate your battery seamlessly into any energy system with reLi's robust communication solutions. Our experienced engineers and embedded platform mastery enable compatibility with various protocols, including MODBUS, CAN bus, and more. Make your battery energy storage system IoT-ready, monitor and control with industrial-standard communication protocols, and develop a master BMS for efficient module management."
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar />
            <ImageHeader 
                image={header}
                head1="BATTERY COMMUNICATION"
                head2="Enabling battery to talk to other energy devices"
                content="Practically all modern devices and systems require interfaces and bus systems to exchange data, reLi’s strong embedded platform and experienced engineers have the capability to integrate battery energy storage in any complex energy system by mastering in various communication protocols."
                button={buttons}
            />
            <Trail
                contents={[
                    {head:"Communication for different purposes",image:communication,
                    content:[
                        "Make your battery energy storage compatible to any inverter or EMS with reLi’s embedded software using protocols like MODBUS, CAN bus, etc.",
                        "Monitor and control battery energy storage system with IoT platform using industrial standard communication protocols.",
                        "Develop a master BMS to manage all battery modules by using protocols like CAN bus, MODBUS, I2C, SPI, isoSPI, etc."
                    ]},
                ]}
            />
            <FooterBar />
        </>
    )
}
export default BatteryCommunication;