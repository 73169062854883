import React, { useState } from "react";
import { ReactDOM, Link, NavLink } from "react-router-dom";
import { ReactSVG } from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faBars, faX, faCircleUser, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

import "./NavBar.css"
import pageRoutes from "../../routes.js";

const dropdownmenu = (subroutes,categories) =>{
    const subnavbarcategories = [];
    subnavbarcategories.push(
        categories.map((category,index)=>{
            const subnavbarmenus = [];
            subnavbarmenus.push(subroutes.map((subroute,i)=>{
                if (subroute.category === index)
                    return subroute.navbar? (
                    <Link to={subroute.path} className="dropdown-menu-button">
                        <img src={subroute.contexticon} className="dropdown-menu-button-icon"/>
                        <div className="dropdown-menu-button-content-div">
                            <div className='dropdown-menu-button-content dropdown-menu-button-content-head head5'>{subroute.name}</div>
                            <div className='dropdown-menu-button-content dropdown-menu-button-content-content content-small'>{subroute.context}</div></div></Link>) : null}));
            return(
                    <div className="dropdown-menu-categories">
                        <div className="dropdown-menu-category-title head5">{category}</div>
                        <div className="dropdown-menu-category-menus">{subnavbarmenus}</div>
                    </div>
            );    
        })
    );
    return <div className="dropdown-menu">
        {subnavbarcategories}
        <div className="dropdown-options">
            <div className="dropdown-menu-categories">
                <div className="dropdown-menu-category-title head5">Need help?</div>
                <Link to='/resources/demo' target="_blank" className="dropdown-option-button content-small">Request demo <FontAwesomeIcon icon={faUpRightFromSquare} size="xs"/></Link>
                <Link to='/contact' target="_blank" className="dropdown-option-button content-small">Contact sales <FontAwesomeIcon icon={faUpRightFromSquare} size="xs"/></Link>
            </div>
        </div>
        </div>
}

const MobileNavbarmenu = (menu) =>{
    const [submenuOpen,setSubMenuOpen] = useState(false);
    const toggleMenuOpen = () =>{
        setSubMenuOpen(!submenuOpen);
    }
    if(menu.subnavbar){
        return (
            <div className="navbar-mobile-menu-button button button-false" onClick={toggleMenuOpen}>
                {menu.name}
                <div className={`navbar-menu-button-dropdown-icon-${menu.name}-active`}><FontAwesomeIcon className="navbar-menu-button-dropdown-icon-fa" size="sm" icon={faAngleDown}/></div>
                <div className={`navbar-mobile-menu-button-dropdown-${menu.name}-active`}>{dropdownmenu(menu.routes,menu.categories)}</div>
            <style jsx> {`
                .navbar-menu-button-dropdown-icon-${menu.name}-active{
                    rotate: ${submenuOpen ? '180deg':'0deg'};
                }
                .navbar-mobile-menu-button-dropdown-${menu.name}-active{
                    display: ${submenuOpen ? 'block':'none'};
                    width:100%;
                }
            `}</style>
            </div>
            
        )
    }
    return <Link to={menu.path} className="navbar-mobile-menu-button button button-false">{menu.name}</Link>
}

const Navbarmenu = (menu,path) =>{
    let active = 'button-inactive';
    if(path === menu.path)
        active = 'button-active';
    if(menu.subnavbar){
        return (
            <div className={`navbar-button-dropdown button button-false ${active}`}>
                {menu.name}
                <div className={`navbar-mobile-menu-button-dropdown-active`}>{dropdownmenu(menu.routes,menu.categories)}</div>
            </div>
            
        )
    }
    return <Link to={menu.path} className={`navbar-button-dropdown button button-false ${active}`}>{menu.name}</Link>
}

const NavBar = () =>{
    let path = '/'+window.location.pathname.split('/').slice(1)[0];
    const [menuOpen,setMenuOpen] = useState(false);
    const handleResize = () =>{
        setMenuOpen(false);
    }
    const toggleMenuOpen = () =>{
        setMenuOpen(!menuOpen);
    }
    
    React.useEffect(() => {
        window.addEventListener('resize', handleResize)
      
        return () => window.removeEventListener('resize', handleResize)
      }, [])

    const navbarmenus = [];
    navbarmenus.push(pageRoutes.map((route,index)=>{return route.navbar? Navbarmenu(route,path) : null}));
    const mobilenavbarmenus = [];
    mobilenavbarmenus.push(pageRoutes.map((route,index)=>{return route.navbar? MobileNavbarmenu(route,index) : null}));
    
    return(
        <div className="navbar">
            <nav className="navbar-nav">
                <Link to="/" className="navbar-logo">
                    <img src="https://relienergy.de/common/reLi_logo.svg" alt='reLi Energy'/>
                </Link>
                <div className="navbar-menu desktop">
                    {navbarmenus}
                </div>
                <div className="navbar-mobile-menu mobile">
                    <div onClick={toggleMenuOpen}>
                        <FontAwesomeIcon size="xl" icon={menuOpen? faX : faBars}/>
                    </div>
                </div>
                <div className="navbar-button-div desktop">
                    <Link to="/login" className="navbar-button contact-button button">Sign in</Link>
                    <Link to="/contact" className="navbar-button contact-button button button-secondary">Contact</Link>
                </div>
            </nav>
            <div className="navbar-mobile-menu-active mobile">
                <nav className="navbar-menu-dropdown-nav">
                    <div className="navbar-menu-dropdown">
                        {mobilenavbarmenus}
                    </div>
                </nav>
            </div>
            <style jsx> {`
                .navbar-mobile-menu-active{
                    display: ${menuOpen ? 'block':'none'};
                }
                .navbar{
                    max-height: ${menuOpen ? '100vh':'none'};
                }
            `}</style>
        </div>
    )
}

export default NavBar;