import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, ProductTiles } from '../../components/ProductSolution/ProductSolution';

import {    
    ProductBatteryAnalytics,
    ProductBatteryCommunication,
    ProductBatteryControl,
    ProductBatteryCostFunction,
    ProductBatteryOptimization,
    ProductBatterySimulation
} from './ProductsList';

import header from '../../assets/img/default-header.jpeg';

import "../page.css";

const EndofLife = () =>{
    return(
        <>
        <MetaData 
                title="End of Life"
                description="Determine the operational potential of your battery asset"
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar />
            <ImageHeader 
                image={header}
                head1="END OF LIFE PHASE"
                head2="Determine the operational potential of your battery asset"
                content="Understanding the lifecycle of your battery asset for extracting the maximum potential while operating the battery in safe limits"
            />
            <ProductTiles 
                products={[
                    ProductBatteryAnalytics,
                    ProductBatterySimulation
                ]}
            />
            <FooterBar />
        </>
    )
}

export default EndofLife;