import React from "react";

import NavBar from "../../../components/NavBar/NavBar";
import FooterBar from "../../../components/FooterBar/FooterBar";
import MetaData from "../../../components/MetaData/MetaData";

import { ImageHeader, ArticlePage, ArticleShare,
    ArticleTitle, ArticleSection, ArticleContents, ArticleImage,
    ArticleQuote, ArticleExample, ArticleList} from "../../../components/ArticleData/ArticleData";

import SimulationToolHeader from '../../../assets/img/simulation-tool-header.jpg';

import KPI from '../../../assets/img/article/kpi-simulation-tool.png';
import Summary from '../../../assets/img/article/summary-simulation-tool.png';


function SimulationTool () {
    return(
        <>
            <MetaData 
                title="Revolutionize Renewable Energy Planning: Explore reLi's Simulation Tool"
                description="Dive into reLi's Simulation Tool, a powerful platform shaping the future of renewable energy. Simulate battery performance, optimize sizing, and evaluate financial metrics. Uncover insights for strategic decision-making in energy management, guiding you towards a greener and more efficient future."
                image={SimulationToolHeader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar />
            <ImageHeader
                head1="Simulation Tool"
                image={SimulationToolHeader}
            />
            <ArticlePage>
                <ArticleTitle
                    head="Elevating Renewable Energy Decision-Making"
                />
                
                <ArticleContents
                    contents={[
                        "In the fast-paced landscape of renewable energy, the need for well-informed decisions has never been more crucial. Imagine the capability to harness your real-world data to simulate and optimize diverse aspects of your energy infrastructure. Enter our Simulation Tool – a robust platform meticulously crafted to empower you in making strategic decisions for a sustainable energy future.",
                        "In this article you will be able to witness the transformative impact of reLi as we present simulations of battery performance while using reLi  and while utilizing state-of-the-art technology. Additionally, you will be able to simulate your battery performance before you install it to plan the sizing, investment needs, and predict your earnings.This comparative analysis highlights how reLi fundamentally alters battery operation, influencing factors such as degradation and revenues. Here, you can discern the distinct advantages that reLi introduces to the realm of renewable energy management."
                    ]}
                />
                <ArticleSection
                    head="A Glimpse into the Simulation Tool"
                    content={"Our Simulation Tool provides a tailored solution for diverse needs. The Simulation Tool opens the door to a wealth of insights, providing a deeper understanding of your energy ecosystem, both with and without the innovative reLi algorithm:"}
                    inverse={true}
                >        
 
                        <ArticleList
                            contents={[
                                {head:"Perfect Sizing of Battery: ",content:"Optimal battery sizing tailored to your specific requirements, comparing the outcomes with and without reLi's operation."},
                                {head:"Financial Metrics: ",content:" Calculate the payback period with and without reLi's operation, net present value, and cash flow projections to assess the algorithm's impact on financial performance."},
                                {head:"Operational Predictions: ",content:"Gain foresight into the years of operations, factoring in the impact of reLi's operation, allowing you to see the long-term benefits."},
                                {head:"Key Performance Indicators (KPIs): ",content:"Evaluate a range of KPIs to measure the success and efficiency of your energy strategy, with a comparative analysis highlighting reLi's contributions."},
                            ]}
                            numbered={true}
                        />
                </ArticleSection>

                <ArticleSection
                    head="How It Works"
                    content={"Empower your energy decisions with our Simulation Tool. Input crucial parameters effortlessly, from your project such as geographical details, specifics about your renewable sources, load and battery information. The Simulation Tool opens the door to a wealth of insights, providing a deeper understanding of your energy ecosystem, both with and without the innovative reLi algorithm:"}
                    inverse={false}
                > 

                <ArticleImage
                        image={Summary}
                        label="Simulation Tool: Summary"
                        portrait={false}
                    >

                <ArticleList
                            contents={[
                                {head:"Input Your Data: ",content:"Begin by entering relevant information into the Simulation Tool – from location details to the specifics of your renewable energy components, customization is at your fingertips."},
                                {head:"Run Simulations: ",content:"Watch as the tool processes your data, running simulations that consider myriad factors to provide accurate and actionable insights."},
                                {head:" Receive Comprehensive Reports: ",content:"Access detailed reports presenting a holistic view of your energy scenario as described in the next section."},
                            ]}
                            numbered={true}
                        />
                </ArticleImage>
                
                </ArticleSection> 

                <ArticleSection
                    head="The Results"
                    content={"After running the simulations, a comprehensive set of results becomes accessible. Initially, a concise summary is presented, encompassing essential aspects such as battery and solar capacity, annual solar production, and annual demand."}
                    inverse={true}
                > 

                <ArticleImage
                        image={KPI}
                        label="Simulation Tool: KPI"
                        portrait={false}
                    >
                
                <ArticleContents
                    contents={[
                        "Furthermore, the financial analysis is outlined, offering insights into the battery cost and discount rate. A visual representation, displayed graphically on the website, illustrates the revenues obtained with reLi compared to the state of the art.",
                        "For those seeking a more in-depth financial analysis, the simulation tool offers additional metrics, including the payback period and net present value. This detailed examination includes a comparative analysis between reLi's operation and normal operation, providing customers with a nuanced understanding of the financial implications associated with their renewable energy decisions."
                    ]}
                />
                </ArticleImage>

                
                </ArticleSection> 



                <ArticleTitle
                    head="Embrace the Future of Energy Management"
                />
                <ArticleContents
                    contents={[
                        "With our Simulation Tool, the future of energy management is in your hands. Uncover the potential of your renewable energy investments and embark on a journey towards a greener, more efficient tomorrow. This is the first step into utilizing reLi's product. By understanding reLi's impact, you can decide what's best for your business or plan your battery installations, taking informed decisions.",
                        "Ready to experience the power of simulation? The Simulation Tool is already available on our website. Request a view-only login or contact us for full access to the tool. Dive into the future of energy with us."
                    ]}
                />

                <ArticleShare
                    request="Request login"
                    requestlink='/resources/demo'
                    link={encodeURIComponent(window.location.href)}
                />
                


            </ArticlePage>
            <FooterBar />
        </> 

        )
    }

export default SimulationTool;