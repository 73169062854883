import React from "react";

import './Administrative.css';

const ImageHeader = ({image,head1}) =>{
    return(
        <div className="admin-image-header-small" style={{backgroundImage:`linear-gradient(0.25turn, rgba(255, 255, 255, 1), rgba(21, 54, 138, 0.1)),url(${image})`}}>
            <div className="admin-image-block gen-image-block-head head1">{head1}</div>
        </div>
    )
}

const HeadOne = ({head}) =>{
    return <div className="admin-head head3">{head}</div>
}

const HeadTwo = ({head}) =>{
    return <div className="admin-head head4">{head}</div>
}

const HeadThree = ({head}) =>{
    return <div className="admin-head head5">{head}</div>
}

const Content = ({content}) =>{
    return <div className="admin-content content-small">{content}</div>
}

const List = ({list}) =>{
    let listcontents = []
    listcontents.push(list.map((listcontent,index)=>{return <li><b>{listcontent.head}</b>{listcontent.content}</li>}))
    return <ul className="admin-list content-small">{listcontents}</ul>
}

const AdministrativePage = ({children}) => {
    return <div className="admin-page">{children}</div>
}

export { ImageHeader, AdministrativePage, HeadOne, HeadTwo, HeadThree, Content, List };