import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, Trail, IconTiles } from '../../components/ProductSolution/ProductSolution';

import header from '../../assets/img/default-header.jpeg';

import generalimg from '../../assets/img/products/BA-general.jpeg';
import SoHimage from '../../assets/img/products/BA-SoH-image.jpeg';
import energyimage from '../../assets/img/products/BA-battery-energy-image.jpeg';
import operationimage from '../../assets/img/products/BA-battery-operation-image.jpeg';

import "../page.css";

const BatteryAnalytics = () =>{
    let buttons=[
        {label:"Request Demo",link:"/resources/demo"}
    ]
    return (
        <>
            <MetaData 
                title="Battery Analytics Solutions - Understand your battery energy storage"
                description="Empower your energy decisions with reLi's advanced battery analytics. Gain deep insights into battery performance, health, and efficiency. Elevate your energy storage strategy with our cutting-edge analytics solutions."
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar />
            <ImageHeader 
                image={header}
                head1="BATTERY INSIGHTS AND ANALYTICS"
                head2="Understand the inside of your battery energy storage"
                content="Estimating the battery’s real-time parameters using reLi’s insights, enables battery asset owners to manage, operate and maintain the battery energy storage system for improved performance and lifetime."
                button={buttons}
            />
            <Trail
                contents={[
                    {head:"Unlock key battery parameters to optimize operations",image:generalimg,
                    content:[
                        "Accurately estimate SoC and SoH of the battery from real-time filed data.",
                        "Determine the instantaneous operatable capacity of the battery considering the imbalances in the cells.",
                        "Aggregate an entire portfolio of battery storage for determining combined capabilities."
                    ]},
                    {image:operationimage,head:"Effectively manage the warranty terms and safe operation with automated alert system"},
                    {image:energyimage,head:"Track the performance to compare with manufacturer’s commitment and conduct preventive maintenance on early detection of performance deviation"},
                    {image:SoHimage,head:"Simulate the rest of the battery’s lifetime to determine the EoL (end of life) and plan future investments",},
                ]}
            />
            <FooterBar />
        </>
    )
}
export default BatteryAnalytics;