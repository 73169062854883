import React from "react";

import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";

import { ImageHeader, Trail } from '../../components/ProductSolution/ProductSolution';

import header from '../../assets/img/default-header.jpeg';

import dispatchcontrol from '../../assets/img/products/BC-dispatch-control.jpeg';
import aggregatebattery from '../../assets/img/products/BC-aggregate-battery.jpeg';
import report from '../../assets/img/products/BC-battery-report.jpeg';
import softwareOTA from '../../assets/img/products/BC-software-OTA.jpeg';
import debugging from '../../assets/img/products/BC-battery-debugging.jpeg';

import "../page.css";

const BatteryControl = () =>{
    let buttons=[
        {label:"Request Demo",link:"/resources/demo"}
    ]
    return (
        <>
            <MetaData 
                title="Battery Remote Control Solutions - Revolutionize Battery Management"
                description="Unlock a suite of features with reLi's remote battery management. Safely and efficiently control battery operations, implement over-the-air software updates, and troubleshoot issues remotely. Explore the future of battery control for optimal performance and reliability."
                image=""
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar />
            <ImageHeader 
                image={header}
                head1="BATTERY CONTROL"
                head2="Batteries made easier to operate and maintain"
                content="Remote management of batteries open a wide variety of features like operational control methods for efficient and safely operation, over the air software updates, debugging operational issues and building new features."
                button={buttons}
            />
            <Trail
                head="Deep dive into battery control"
                contents={[
                    {image:dispatchcontrol,head:"Battery Dispatch and Control",content:"Cloud based dispatch with hardware-based control enables battery operation to be preplanned, communicated securely and locally handle deviations in operation."},
                    {image:aggregatebattery,head:"Aggregate Battery Asset Control",content:"Multiple battery assets visualized in a single cloud platform with automated monitoring and dispatch planning makes aggregation of battery assets easier than ever before."},
                    {image:report,head:"Battery as a Service Solution",content:"Cloud control enables the easy remote management of the battery that allows battery users to lease or rent a battery but still providing the owner to have total control over the battery."},
                    {image:softwareOTA,head:"Remote Battery Software Update",content:"Over the air updates for rollout of new features or addressing issues in battery software is easily deployable with remote management of battery."},
                    {image:debugging,head:"Debugging Issues on Battery",content:"Effortlessly troubleshoot and resolve battery issues with remote management, providing efficient and reliable debugging capabilities for seamless performance optimization."},
            ]}
            />
            <FooterBar />
        </>
    )
}
export default BatteryControl;