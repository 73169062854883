import NavBar from "../../components/NavBar/NavBar";
import FooterBar from "../../components/FooterBar/FooterBar";
import MetaData from "../../components/MetaData/MetaData";
import { CareerBlock } from "../../components/General/General";

import careerHeader from "../../assets/img/career-header.jpeg";

function OpenPosition () {
    return(
        <>
            <MetaData 
                title="Open Application to join reLi Energy"
                description="Join reLi Energy's team and contribute to reshaping the landscape of energy optimization and storage."
                image={careerHeader}
                url={process.env.REACT_APP_APP_HOST+window.location.pathname}
            />
            <NavBar/>
            <CareerBlock
                position="Open Application"
                applicationid="G000"
                benifits={[
                    "Wide opportunity for networking and exposure to entrepreneurship",
                    "Collaborative and inclusive work environment",
                    "Opportunities for innovation ",
                    "Flexible work hours and the ability to work remotely ",
                    "Being part of a diverse, dynamic and enthusiastic team",
                    "Opportunity to work on cutting-edge projects in sustainable energy.",
                    "Collaborative and innovative work environment.",

                ]}
            />
            <FooterBar/>
        </>
    )
}

export default OpenPosition;